// Polyfills Vue.js router to avoid throwing on navigation errors
import Router from 'vue-router'
import { logger } from '@/utils/logger'
;['push', 'replace'].forEach((method) => {
    // @ts-ignore
    const originalMethod = Router.prototype[method]
    // @ts-ignore
    Router.prototype[method] = function m(location) {
        return originalMethod.call(this, location).catch((error: any) => {
            // The these errors are innocuous issues that we don't want to throw for
            // https://router.vuejs.org/guide/advanced/navigation-failures.html#navigationfailuretype
            logger.error('Navigation failure', error)
        })
    }
})
