// Polyfill
import 'intersection-observer'
import './utils/polyfillRouter'

// Set logging prototype as soon as possible
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueMask from 'v-mask'
import VueObserveVisibility from 'vue-observe-visibility'
import { logger } from '@/utils/logger'
import App from './App.vue'
import NetworkUnavailable from './NetworkUnavailable.vue'
import router from './routes/router'
import store from './store'
import './utils/validation'
import { ValidationProvider } from 'vee-validate'
import './utils/exception-handler'
import { i18n } from './utils/i18n'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { initNextDoor } from '@/services/nextDoor'
import { initFacebook } from '@/services/facebook'
import { initializeSessionRecording } from '@/services/marketing'
import { PlaidLinkOptions } from 'react-plaid-link/src/types'

declare global {
    interface Window {
        logEvent: any
        LogRocket: any
        previousPath: string
        ndp: any
        fbq: any
        _fbq: any
        zE: any
        mobileCheck: any
        plaidOptions: PlaidLinkOptions
    }
}

Vue.prototype.$logger = logger // we add logger to the prototype so that we can access it in aven_shared
Vue.prototype.$logEvent = window.logEvent

// we need to register this component globally so that we can use it in aven_shared templates
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(VueObserveVisibility)

Vue.use(VueMask, {
    // This allows us to use the 'X' character as a regular char
    // https://github.com/probil/v-mask#default-placeholders
    placeholders: {
        X: null,
    },
})

export const currentContextForLogging: any = {}

Vue.mixin({
    created() {
        try {
            const name = this.name
            const vnode = this.$vnode
            const uid = this.uid as string
            if (name || vnode?.tag || uid) {
                currentContextForLogging[name || vnode?.tag || uid] = this
            }
        } catch (e) {
            logger.fatal(`error adding logging context of component ${this?.name || this?.$vnode?.tag || this?.uid}`, e)
        }
    },
})

let isMounted = false
const initVue = () => {
    // Don't let Vue get mounted twice (this function could be fired twice for various reasons)
    if (isMounted) {
        return
    }

    // Init session recording ASAP
    initializeSessionRecording()

    // Listen for + log when vue-loaded is fired (helps with debugging)
    document.addEventListener('vue-loaded', function () {
        logger.log('Vue loaded!')
    })

    logger.log('Enabling vue...')
    new Vue({
        router,
        i18n,
        store,
        render: (h) => h(App),
    }).$mount('#app')
    isMounted = true

    initNextDoor()
    initFacebook()

    // This triggers the prerender script to capture the page
    // In normal modes, fires a message to the console
    const vueLoadedEvent = new Event('vue-loaded')
    document.dispatchEvent(vueLoadedEvent)
}

const initVueNetworkUnavailable = () => {
    if (isMounted) {
        return
    }

    // Don't bother sending to the backend, unlikely to succeed + generates tons of console errors
    logger.setNetworkLogging(false)
    logger.log('Enabling vue (network unavailable)...')
    new Vue({
        i18n,
        render: (h) => h(NetworkUnavailable),
    }).$mount('#app')

    isMounted = true
}

function getMetadata() {
    return {
        resolution: window.innerWidth + 'x' + window.innerHeight,
        cookies: document.cookie,
        // Pass raw query string to allow backend to parse however it likes
        queryParams: window.location.search,
        path: window.location.pathname,
        avenProperty: 'AUTO',
    }
}

function fireSessionIdRequest() {
    const sessionIdReadyEvent = new Event('sessionIdReady')

    const req = new XMLHttpRequest()
    req.onreadystatechange = function () {
        // When request ready + successful.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            const resp = JSON.parse(this.responseText)
            console.log('Setting sessionId: ' + resp.payload.sessionId)
            appSessionStorage.setItem(sessionStorageKey.sessionAccessJWT, resp.payload.jwt.sessionAccessJWT)
            appSessionStorage.setItem(sessionStorageKey.sessionId, resp.payload.sessionId)
            appSessionStorage.setItem(sessionStorageKey.experimentName, resp.payload.experimentName)
            appSessionStorage.setItem(sessionStorageKey.underwritingMetaData, JSON.stringify(resp.payload.underwritingMetadata || {}))
            appSessionStorage.setItem(sessionStorageKey.creditCardMarketData, JSON.stringify(resp.payload.creditCardMarketData || {}))
            window.dispatchEvent(sessionIdReadyEvent)
        }
        // We abort the request in abortCallIfSessionIdNotCompleteWithinXSeconds() if it takes too long.
        else if (this.readyState === XMLHttpRequest.DONE && this.status === 0) {
            logger.error(`getSessionId request was aborted (usually because the request took too long)`)
        } else if (this.readyState === XMLHttpRequest.DONE) {
            throw new Error('getSessionId call failed with status ' + this.status)
        }
    }
    req.open('POST', `${process.env.VUE_APP_API_BASE_URL}/ana/session`)
    req.setRequestHeader('Content-Type', 'application/json')
    console.log('Requesting sessionId + experimentName, also sending metadata')
    const body = JSON.stringify(getMetadata())
    setTimeout(function () {
        req.send(body)
    }, 100)

    return req
}

function abortCallIfSessionIdNotCompleteWithinXSeconds(xmlHttpRequest: XMLHttpRequest, timeout: number, callback: () => void) {
    setTimeout(function () {
        if (!appSessionStorage.getItem(sessionStorageKey.sessionId)) {
            console.log('Took too long to load, aborting')
            xmlHttpRequest.abort()
            if (callback) {
                callback()
            }
        }
    }, timeout)
}

const isSessionIdPresent = () => {
    return (
        appSessionStorage.getItem(sessionStorageKey.sessionId) &&
        appSessionStorage.getItem(sessionStorageKey.sessionId) !== 'undefined' &&
        appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT) &&
        appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT) !== 'undefined' &&
        appSessionStorage.getItem(sessionStorageKey.experimentName) &&
        appSessionStorage.getItem(sessionStorageKey.experimentName) !== 'undefined'
    )
    // "undefined" is returned from appSessionStorage as a string if undefined was placed into it
}

function init() {
    if (isSessionIdPresent()) {
        // No need to run the rest of the logic, init vue and return
        initVue()
        console.log('sessionId and sessionAccessJWT and experimentName already set')
        return
    }

    // @ts-ignore
    console.log('Waiting for sessionIdReady / networkUnavailable event...')

    window.addEventListener(
        'sessionIdReady',
        function () {
            logger.log('Received sessionIdReady event')
            initVue()
        },
        false
    )

    window.addEventListener(
        'networkUnavailable',
        function () {
            logger.log('Received networkUnavailable event')
            initVueNetworkUnavailable()
        },
        false
    )

    const queryParams = new URLSearchParams(window.location.search)
    const sessionIdReq = fireSessionIdRequest()
    abortCallIfSessionIdNotCompleteWithinXSeconds(sessionIdReq, 5000, function () {
        const timesReloaded = parseInt(queryParams.get('reloaded') as string) || 0

        if (timesReloaded < 3) {
            console.log('Force reloading page because sessionId call failed, page already reloaded ' + timesReloaded + ' times')

            queryParams.set('reloaded', `${timesReloaded + 1}`)
            window.location.search = queryParams.toString()
        } else {
            console.log('Dispatching networkUnavailable event')
            const networkUnavailableEvent = new Event('networkUnavailable')
            window.dispatchEvent(networkUnavailableEvent)
        }
    })
}

init()
